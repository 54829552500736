// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 4. Import UIkit.
@import "~uikit/src/scss/uikit-theme.scss";

.uk-slidenav {
    color: $dark;
    background: $light;
    padding: 8px 13px;
    transition: color $transition-time-1 ease-in-out,
        background-color $transition-time-1 ease-in-out;

    &:hover {
        background: $primary;
        color: $light;
    }
}
