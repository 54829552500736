@use 'sass:math';

body,
#root {
    overflow-x: hidden;
}

// Override default variables before the import
$body-bg: #fff;
$primary: #0095b7;
$danger: #ef4916;
$dark: #112c5a;
$secondary: $danger;
$light: #efefef;

$white: #fff;

$enable-rounded: false;
$enable-negative-margins: true;
$enable-gradients: false;
$gradient: linear-gradient(50deg, rgba($dark, 0.2), rgba($white, 0)) !default;

$link-color: $secondary;

$spacer: 1rem;
$spacers: (
    "grid": 15px,
    "grid2": 30px,
    "neggrid": -15px,
    "navbar": 76px,
    0: 0,
    1: (
        math.div($spacer, 4),
    ),
    2: (
        math.div($spacer, 2),
    ),
    3: (
        $spacer,
    ),
    4: (
        $spacer * 1.5,
    ),
    5: (
        $spacer * 3,
    ),
    6: (
        $spacer * 6,
    ),
    7: (
        $spacer * 7,
    ),
    8: (
        $spacer * 8,
    ),
    9: (
        $spacer * 9,
    ),
    10: (
        $spacer * 10,
    ),
    15: (
        $spacer * 15,
    ),
    20: (
        $spacer * 20,
    ),
    25: (
        $spacer * 25,
    ),
    5p: 5vw,
    10p: 10vw,
    15p: 15vw,
    20p: 20vw,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 1240px,
    xl: 1600px,
) !default;

$base-transition-time: 0.15;
$seconds: s;
$transition-time-1: $base-transition-time + $seconds;
$transition-time-2: $base-transition-time * 2 + $seconds;

/*****************************************************************************************
*                                                                                        *
* IMPORTS                                                                                *
******************************************************************************************/

@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;600;800&display=swap");

$font-family-sans-serif: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.font-weight-bold {
    font-weight: 600;
}

@import "uikitslider.scss";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.footer .footer-nav {
    a {
        color: $gray-700;
        text-decoration: none;
        font-weight: 400;

        &:hover {
            color: $gray-900;
        }
    }
}

/*****************************************************************************************
*                                                                                        *
* NAVIGATION                                                                             *
******************************************************************************************/
@import "navigation.scss";

.navbar {
    background: none;

    .navbar-nav {
        margin: $spacer * 2 0;
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
}

.dropdown-menu {
    @include media-breakpoint-down(lg) {
        border: 0;
    }
}

.navbar,
.offcanvas {
    text-transform: uppercase;
    a {
        text-decoration: none;
        letter-spacing: 0.1rem;
        color: $dark;
    }
}

.nav-item,
.nav-item a {
    font-weight: 800;
    font-size: 1.2rem;

    margin-bottom: $spacer;
    padding-top: 0;
    padding-bottom: 0;

    &.dropdown {
        margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
        margin-right: 1.5rem;
        margin-bottom: 0;

        font-weight: 400;
        font-size: inherit;
    }
}

.nav,
.navbar-light .navbar-nav {
    a.nav-item,
    a.nav-link {
        text-decoration: none;
        color: $gray-600;
        margin-bottom: 0.4rem;

        &.active {
            font-weight: 800;
        }

        &:hover {
            color: $gray-800;
        }
    }
}

.secondary-nav {
    .nav-item {
        font-weight: inherit;
    }
}

[class^="nav-header-"] {
    text-transform: uppercase;
    color: $gray-600;
    margin-bottom: $spacer * 1.5;
}

.navbar-light .navbar-toggler {
    color: $dark;
    border-color: transparent;

    &:focus {
        box-shadow: none;
    }
}

.navbar.bg-primary {
    color: white;

    a {
        color: white;
    }

    .navbar-light .navbar-toggler {
        color: white;
    }

    .dropdown-menu {
        color: $dark;

        a {
            color: $dark;
        }

        .navbar-light .navbar-toggler {
            color: $dark;
        }
    }
}

.navbar-nav {
    .dropdown-menu {
        padding: 1.5rem 1rem 1rem;

        @include media-breakpoint-up(lg) {
            margin-left: -3rem;
        }

        a.nav-item.dropdown-item {
            margin-bottom: 0.8rem;
            padding: 0.3rem 0;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            background-color: transparent;
        }
    }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
    color: $gray-800;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.nav a.nav-item,
.nav a.nav-link,
.navbar-light .navbar-nav a.nav-item,
.navbar-light .navbar-nav a.nav-link {
    margin-bottom: 0;

    &.active {
        font-weight: 400;
    }
}

/*****************************************************************************************
*                                                                                        *
* NAV-TABS                                                                               *
******************************************************************************************/
nav.nav-tabs {
    border: none;
    margin-bottom: 0.7rem;

    a.nav-item {
        border: none;
        padding: 0.5rem 1rem 0.2rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: $gray-700;

        &:hover {
            color: $gray-800;
        }

        &.active {
            font-weight: 600;
            border-bottom: 3px solid $dark;
            color: $dark;
        }
    }
}

/*****************************************************************************************
*                                                                                        *
* BUTTONS                                                                             *
******************************************************************************************/
button,
.btn {
    &:hover {
        text-decoration: none;
        color: white;
    }

    position: relative;
    z-index: 9999;
    color: white;
    font-weight: 600;
}

form {
    input,
    button,
    textarea,
    select {
        margin-right: $spacer;
        margin-bottom: $spacer;
    }
}

.form-check input + label {
    margin-top: 4px;
}

.form-label {
    display: block;
}

/*****************************************************************************************
*                                                                                        *
* BACKGROUNDS                                                                            *
******************************************************************************************/
.bg-blue-schraeg {
    background-image: url(../images/blue-bg-schraeg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-cloud {
    background-image: url(../images/cloud.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -15% 125%;
    background-size: 70vw;

    /*
    animation-name: cloud-translation;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    */
}

.bg-crosses {
    background-image: url(../images/crosses.svg);
    background-repeat: no-repeat;
    background-position: 120%;
}

.bg-pattern {
    background-color: $dark;
    background-image: url(../images/pattern.svg);
    background-repeat: repeat;
    /*
    background-blend-mode: color-burn;

    animation-name: svg-hue-rotation;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
        */
}

@keyframes svg-hue-rotation {
    0%,
    100% {
        filter: hue-rotate(-30deg);
    }
    50% {
        filter: hue-rotate(30deg);
    }
}

@each $name, $value in $grays {
    .bg-gray-#{$name} {
        background: $value;
    }
}

.bg-blue-animated {
    background-color: $dark;
    background-image: url(../images/bg-blue-gradient.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-black-animated {
    background-color: $dark;
    background-image: url(../images/bg-black-gradient.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-fixed {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
}

.bg-fighters {
    background-image: url(../images/shutterstock_2104513721_small.png);


    @include media-breakpoint-up(lg) {
        background-image: url(../images/shutterstock_2104513721.png), url('../images/logo_outline.svg');
    }

    background-size: contain, 90%;
    background-repeat: no-repeat,no-repeat;
    background-position-y: 100%, 25%;
    background-position-x: 100%,center;
}

/*****************************************************************************************
*                                                                                        *
* UTILITIES                                                                              *
******************************************************************************************/
.text-vertical {
    transform: rotate(-90deg);
    transform-origin: top left;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;

    h2,
    .h2 {
        margin-left: -2rem;
    }
}

.float-start,
.float-end {
    @include media-breakpoint-down(md) {
        float: none !important;
    }
}

.float-start {
    @include media-breakpoint-up(md) {
        margin-right: 4.5rem;
    }
}

.float-end {
    @include media-breakpoint-up(md) {
        margin-left: 4.5rem;
    }
}

.no-overflow {
    overflow: hidden;
}

.content-box {
    box-sizing: content-box;
}

.vh-100-minus-navbar {
    height: 100vh;
    height: calc(100vh - 76px);
}

.min-vh-25 {
    min-height: 25vh !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.min-vh-75 {
    min-height: 75vh !important;
}

.vh-25 {
    height: 25vh;
}
.vh-50 {
    height: 50vh;
}
.vh-75 {
    height: 75vh;
}

.zoom {
    &,
    * {
        transition: transform $transition-time-2 ease;
    }

    &:hover {
        transform: scale(1.5);
        p {
            transform: scale(0.75);
            transform-origin: 0;
        }
    }
}

.table-responsive {
    //scrollbar-gutter: stable;

    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #242a33;
        background-color: rgba(36, 42, 51, 0.6);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary !important;
        border-radius: 4px;
        width: 1rem;
    }
}

.accordion-flush {
    .btn:hover,
    button:hover {
        color: $gray-700;
    }
}

/*****************************************************************************************
*                                                                                        *
* TEXT                                                                                  *
******************************************************************************************/
.text-right {
    text-align: right;
}

a {
    font-weight: 600;
}

.page-header {
    margin-top: 4.5rem;
    margin-bottom: 3rem;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: inherit;
    }
}

.display-1 {
    font-family: "Alegreya Sans";
    font-weight: 900;
    line-height: 1;
}

.text-white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white;
    }

    small,
    .display-5 {
        color: $gray-500;
    }
}

.display-5 {
    text-transform: uppercase;
    font-family: "Archivo";
    font-weight: 400;
    letter-spacing: 0.4rem;
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 800;
    font-family: "Archivo";
}

h1,
.h1 {
    margin-bottom: $spacer * 2;
}

h2,
.h2 {
    margin-top: $spacer * 1.75;
}

h3,
.h3 {
    margin-top: $spacer * 1.5;
}

@supports (-webkit-text-stroke: 1px $secondary) {
    .text-white {
        &.text-stroke,
        .text-stroke {
            &.display-1 {
                -webkit-text-stroke: 1px white;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-bottom: 0.25em;
}

@import "print.scss";

.filebrowser a {
    text-decoration: none;
    cursor: pointer;

    border: 1px solid transparent;
    border-radius: 3px;

    margin: 0.2rem 0 0;
    padding: 0.25rem 0.5rem 0.2rem;

    &.selected {
        border: 1px solid $green;
    }
}
